<template>
  <div class="calls-single-list">
    <div class="d-flex justify-content-end">
      <button
        style="margin-bottom: 10px;"
        class="btn btn-primary"
        @click="initiateCallModalOpen = true"
      >
        {{ $t('order-confirmation.calls.excel.upload-excel') }}
      </button>
    </div>
    <div class="calls-single-table">
      <data-table
        :data="callFiles"
        :fields="translatedTableFields"
        :pagination="pagination"
        @changePage="onPageChange($event)"
        :loading="callFilesLoading"
      >
        <template v-slot:status="{ row }">
          <span :class="getStatusClass(row.status)">
            {{ getStatus(row) }}
          </span>
        </template>
        <template v-slot:progress="{ row }">
          <div class="d-flex align-items-center">
            <b-progress
              class="flex-fill"
              :value="row.progress"
              :max="100"
              animated
            ></b-progress>
            <span class="mx-2"></span>
            <span> {{ row.progress.toFixed(2) }}% </span>
          </div>
        </template>

        <template v-slot:actions="{ row }">
          <excel-calls-actions
            :row="row"
            @downloadExcelFile="downloadExcelFile(row)"
            @changeStatus="row.status = $event"
          ></excel-calls-actions>
        </template>
      </data-table>
    </div>

    <modal
      :isOpen="initiateCallModalOpen"
      @dismiss="initiateCallModalOpen = false"
    >
      <div class="d-flex flex-column align-items-center">
        <div class="head mb-4">
          <h1 class="m-0">
            {{
              $t('order-confirmation.calls.excel.initiate-calls-modal.title')
            }}
          </h1>
        </div>
        <div class="body d-flex flex-column align-items-center">
          <div class="d-flex align-items-center">
            <b-form-select
              class="border mx-2 flex-fill"
              v-model="selectedScenario"
              :options="callScenarios"
            >
              <template v-slot:first>
                <b-form-select-option disabled value="">
                  {{
                    $t(
                      'order-confirmation.calls.excel.initiate-calls-modal.form.select-flow.placeholder'
                    )
                  }}
                </b-form-select-option>
              </template>
            </b-form-select>
            <button
              v-if="selectedScenario"
              @click="downloadTemplate(selectedScenario)"
              type="button"
              class="btn btn-primary btn-sm px-3"
              :title="
                $t(
                  'order-confirmation.calls.excel.initiate-calls-modal.download-sample'
                )
              "
            >
              <i class="fa fa-download" aria-hidden="true"></i>
            </button>
          </div>

          <dropzone
            v-if="selectedScenario"
            class="mb-2"
            :options="dropzoneOptions"
            v-model="files"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">
                {{
                  $t(
                    'order-confirmation.calls.excel.initiate-calls-modal.form.file.title'
                  )
                }}
              </h3>
              <div class="subtitle">
                {{
                  $t(
                    'order-confirmation.calls.excel.initiate-calls-modal.form.file.subtitle'
                  )
                }}
              </div>
            </div>
          </dropzone>

          <div v-if="selectedScenario">
            <b-form-checkbox size="lg" v-model="useDefaultFlowOptions">
              {{
                $t(
                  'order-confirmation.calls.single.initiate-call-modal.use-default-flow-options'
                )
              }}
            </b-form-checkbox>
            <div class="custom-options" v-if="!useDefaultFlowOptions">
              <div class="row mb-4">
                <div class="col-12">
                  <label for="">{{
                    $t('order-confirmation.create-flow.form.trials-count.label')
                  }}</label>
                  <b-form-input
                    type="number"
                    class="border"
                    min="1"
                    :placeholder="
                      $t(
                        'order-confirmation.create-flow.form.trials-count.placeholder'
                      )
                    "
                    :value="model.trialsCount"
                    @change="onTrialsCountChange"
                    :class="{ 'is-invalid': errors.trialsCount }"
                  ></b-form-input>

                  <span v-if="errors.trialsCount" class="text-danger">
                    {{ errors.trialsCount }}
                  </span>
                </div>
                <div class="col-12 mb-2">
                  <b-form-checkbox size="lg" v-model="model.useDateOption">
                    {{
                      $t(
                        'order-confirmation.create-flow.form.use-dates.use-dates'
                      )
                    }}
                  </b-form-checkbox>
                </div>
                <div class="col-12" v-if="model.useDateOption">
                  <div class="">
                    <div class="">
                      <p class="mb-1">
                        <b>{{
                          $t(
                            'order-confirmation.create-flow.form.use-dates.title'
                          )
                        }}</b>
                      </p>
                    </div>
                    <div
                      class="w-100 mb-2 d-flex align-items-center"
                      v-for="(callDate, idx) of model.callsDates"
                      :key="`trial-${idx}`"
                    >
                      <p class="mb-0 mx-2 text-nowrap">
                        {{
                          $t(
                            'order-confirmation.create-flow.form.use-dates.trial'
                          )
                        }}
                        #{{ idx + 1 }}
                      </p>
                      <date-picker
                        class="flex-fill"
                        :value="model.callsDates[idx].date"
                        @input="onCallDateChange($event, idx)"
                        :config="dateTimeConfig"
                        mode="single"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12" v-else>
                  <div class="">
                    <label for="">{{
                      $t(
                        'order-confirmation.create-flow.form.minutes-to-wait-before-first-trial.label'
                      )
                    }}</label>
                    <b-form-input
                      type="number"
                      class="border"
                      min="0"
                      :placeholder="
                        $t(
                          'order-confirmation.create-flow.form.minutes-to-wait-before-first-trial.placeholder'
                        )
                      "
                      v-model="model.minutesToWaitBeforeFirstTrial"
                      :class="{
                        'is-invalid': errors.minutesToWaitBeforeFirstTrial
                      }"
                    ></b-form-input>
                    <span
                      v-if="errors.minutesToWaitBeforeFirstTrial"
                      class="text-danger"
                    >
                      {{ errors.minutesToWaitBeforeFirstTrial }}
                    </span>
                  </div>
                  <div class="">
                    <label for="">{{
                      $t(
                        'order-confirmation.create-flow.form.delay-minutes-between-trials.label'
                      )
                    }}</label>
                    <b-form-input
                      type="number"
                      class="border"
                      min="0"
                      :placeholder="
                        $t(
                          'order-confirmation.create-flow.form.delay-minutes-between-trials.placeholder'
                        )
                      "
                      v-model="model.delayMinutesBetweenTrials"
                      :class="{
                        'is-invalid': errors.delayMinutesBetweenTrials
                      }"
                    ></b-form-input>
                    <span
                      v-if="errors.delayMinutesBetweenTrials"
                      class="text-danger"
                      >{{ errors.delayMinutesBetweenTrials }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            class="btn btn-primary"
            @click="onInitiateCall"
            :disabled="!files[0] || !selectedScenario || initiateCallLoading"
          >
            {{
              $t(
                'order-confirmation.calls.excel.initiate-calls-modal.initiate-calls'
              )
            }}
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue'
import DataTable from '../../elements/Table.vue'
import Modal from '../../elements/Modal.vue'
import orderConfirmationService from '../../services/orderConfirmation.service'
import { api } from '../../axios'
import FileSaver from 'file-saver'
import ExcelCallsActions from './components/excel-calls-actions.vue'
import Dropzone from '../../elements/Dropzone.vue'
import { yupToKV } from '../../utils/yup'
import { number, object } from 'yup'
import DatePicker from '../../elements/DatePicker.vue'
import moment from 'moment'
import { Arabic } from 'flatpickr/dist/l10n/ar.js'

export default {
  data() {
    return {
      today: moment(),
      dateTimeConfig: {
        minDate: moment().toDate(),
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'F j, Y h:i K',
        altInput: true,
        locale: localStorage.getItem('locale') === 'ar' ? Arabic : null
      },
      fields: [
        {
          accessor: 'fileName',
          header: 'order-confirmation.calls.excel.table.file-name'
        },
        {
          accessor: 'status',
          header: 'order-confirmation.calls.excel.table.status'
        },
        {
          accessor: 'progress',
          header: 'order-confirmation.calls.excel.table.progress'
        },
        {
          accessor: 'actions',
          header: 'order-confirmation.calls.excel.table.actions'
        }
      ],
      callFiles: [],
      callFilesLoading: false,
      pagination: {
        totalPages: 0,
        page: 1
      },
      loading: false,
      initiateCallLoading: false,
      initiateCallModalOpen: false,
      callScenarios: [],
      selectedScenario: '',
      dropzoneOptions: {
        paramName: 'file',
        acceptedFiles: '.csv',
        maxFiles: 1
      },
      files: [],
      useDefaultFlowOptions: true,
      model: {
        trialsCount: '1',
        minutesToWaitBeforeFirstTrial: '0',
        delayMinutesBetweenTrials: '0',
        useDateOption: false,
        callsDates: [{ date: moment().format('YYYY-MM-DD HH:mm'), trial: 1 }]
      },
      errors: {}
    }
  },
  components: { DataTable, Modal, ExcelCallsActions, Dropzone, DatePicker },
  mounted() {
    this.getCallFiles()
    this.getCallScenarios()
  },
  methods: {
    async getCallScenarios() {
      const result = await orderConfirmationService.getOrderConfirmationScenarios()
      this.callScenarios = result.orderScenarios.map(cs => ({
        value: cs.id,
        text: cs.name
      }))
    },
    removeDuplicateParams(arr) {
      const uniqueArray = arr.filter((value, index) => {
        return (
          index ===
          arr.findIndex(obj => {
            return obj.variable_name === value.variable_name
          })
        )
      })

      return uniqueArray
    },
    async onInitiateCall() {
      const data = {
        file: this.files[0],
        useDefaultFlowOptions: this.useDefaultFlowOptions,
        useDateOption: this.model.useDateOption
      }
      let schema = this.customOptionsSchema

      if (!this.useDefaultFlowOptions) {
        data.trialsCount = this.model.trialsCount

        if (!this.model.useDateOption) {
          schema = this.customOptionsSchema
          data.minutesToWaitBeforeFirstTrial = this.model.minutesToWaitBeforeFirstTrial
          data.delayMinutesBetweenTrials = this.model.delayMinutesBetweenTrials
        } else {
          schema = this.useDateOptionSchema
          data.callsDates = JSON.stringify(this.model.callsDates)
        }
      }

      await schema.validate(data, { abortEarly: false }).catch(err => {
        this.errors = yupToKV(err)
      })

      if (!schema.isValidSync(data)) return

      try {
        this.initiateCallLoading = true
        this.errors = {}

        await orderConfirmationService.initiateCallFile(
          this.selectedScenario,
          data
        )
        this.initiateCallModalOpen = false
        this.model = {
          trialsCount: '0',
          minutesToWaitBeforeFirstTrial: '0',
          delayMinutesBetweenTrials: '0',
          useDateOption: false,
          callsDates: [{ date: moment().format('YYYY-MM-DD HH:mm'), trial: 1 }]
        }
        this.getCallFiles()
      } catch (error) {
        this.toast(error.response.data.message, { type: 'error' })
      } finally {
        this.initiateCallLoading = false
      }
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getCallFiles(page)
    },
    async getCallFiles(page = 1, limit = 10) {
      this.callFilesLoading = true

      const result = await orderConfirmationService.getCallFiles(page, limit)
      const { callsFiles, ...pagination } = result
      this.callFiles = callsFiles
      this.pagination.totalPages = pagination.totalPages

      this.callFilesLoading = false
    },
    async downloadExcelFile(fileCall) {
      await api
        .get(
          `order-confirmation/scenario/calls-files/${fileCall.id}/download-statistic`,
          {
            responseType: 'arraybuffer'
          }
        )
        .then(res => {
          var blob = new Blob([res.data], {
            type: res.headers['content-type']
          })
          FileSaver.saveAs(blob, fileCall.fileName)
        })
        .catch(e => {
          this.toast(error.message, {
            type: 'error'
          })
        })
    },
    downloadFile(data, fileName) {
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      const blob = new Blob([data], { type: 'octet/stream' }),
        url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(url)
    },
    async downloadTemplate(flowId) {
      console.log(this.callScenarios)
      const flow = this.callScenarios.find(val => val.value === flowId)
      const file = await orderConfirmationService.getScenarioTemplate(
        flow.value
      )
      this.downloadFile(file, `${flow.text}.csv`)
    },
    getStatusClass(status) {
      return status
        .toLowerCase()
        .trim()
        .split(' ')
        .join('-')
    },
    getStatus(row) {
      if (row.progress == 100)
        return this.$t('order-confirmation.calls.excel.table.statuses.finished')
      return this.fileStatus[row.status]
    },
    onTrialsCountChange(value) {
      this.model.trialsCount = value
      this.model.callsDates = Array.from(
        { length: Math.min(value, 10) },
        (_, idx) =>
          this.model.callsDates[idx] || {
            trial: idx + 1,
            date: moment().format('YYYY-MM-DD HH:mm')
          }
      )
    },
    onCallDateChange(value, idx) {
      this.model.callsDates[idx].date = value
    }
  },
  computed: {
    locale() {
      return Vue.i18n.locale()
    },
    translatedTableFields() {
      return this.fields.map(field => {
        return {
          ...field,
          header: this.$t(field.header)
        }
      })
    },
    fileStatus() {
      return {
        'file-uploaded': this.$t(
          'order-confirmation.calls.excel.table.statuses.file-uploaded'
        ),
        'verifying-headers': this.$t(
          'order-confirmation.calls.excel.table.statuses.verifying-headers'
        ),
        'verifying-content': this.$t(
          'order-confirmation.calls.excel.table.statuses.verifying-content'
        ),
        'content-verified': this.$t(
          'order-confirmation.calls.excel.table.statuses.content-verified'
        ),
        'inserting-data': this.$t(
          'order-confirmation.calls.excel.table.statuses.inserting-data'
        ),
        failed: this.$t('order-confirmation.calls.excel.table.statuses.failed'),
        ready: this.$t('order-confirmation.calls.excel.table.statuses.ready'),
        paused: this.$t('order-confirmation.calls.excel.table.statuses.paused'),
        'corrupted-ignored': this.$t(
          'order-confirmation.calls.excel.table.statuses.corrupted-ignored'
        ),
        cancelled: this.$t(
          'order-confirmation.calls.excel.table.statuses.cancelled'
        ),
        'calls-in-progress': this.$t(
          'order-confirmation.calls.excel.table.statuses.calls-in-progress'
        )
      }
    },
    customOptionsSchema() {
      return object().shape({
        trialsCount: number().min(
          1,
          this.$t('order-confirmation.create-flow.errors.length.trials-count', {
            num: 1
          })
        ),
        minutesToWaitBeforeFirstTrial: number().min(
          0,
          this.$t(
            'order-confirmation.create-flow.errors.length.minutes-to-wait-before-first-trial',
            { num: 0 }
          )
        ),
        delayMinutesBetweenTrials: number().min(
          5,
          this.$t(
            'order-confirmation.create-flow.errors.length.delay-minutes-between-trials',
            { num: 5 }
          )
        )
      })
    },
    useDateOptionSchema() {
      return object().shape({})
    }
  }
}
</script>

<style lang="scss" scoped>
.params-group {
  background-color: #f5f5f5;
  padding: 12px;
  border-radius: 14px;
}

.file-uploaded {
  color: rgb(15, 100, 103);
}

.verifying-headers {
  color: rgb(13, 7, 52);
}

.verifying-content {
  color: orange;
}

.content-verified {
  color: rgb(19, 189, 155);
}

.inserting-data {
  color: #2e20bb;
}

.paused {
  color: #2061bb;
}

.failed {
  color: red;
}

.ready {
  color: green;
}

.corrupted-ignored {
  color: #34ca66;
}

.cancelled {
  color: brown;
}
</style>
<style>
td:has(> .actions-cell) {
  width: 35%;
}
</style>
